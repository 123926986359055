// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// eslint-disable-next-line no-unused-vars
import jQuery from 'jquery';
import React from 'react';
import { createRoot } from 'react-dom/client';
import AppContainer from './appContainer';
import ErrorContainer from './errorContainer';

require('@rails/ujs').start();
require('@rails/activestorage').start();
// eslint-disable-next-line import/no-unresolved
require('channels');

function initializeGTM() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WNGS8ZR');
}

const reactComponents = {
  AppContainer,
  ErrorContainer,
};

function initializeReact() {
  const containers = document.querySelectorAll('.react-component');

  Array.from(containers).forEach((container) => {
    const ContainerComponent = reactComponents[container.id];

    const root = createRoot(container);

    // eslint-disable-next-line react/jsx-filename-extension,react/jsx-props-no-spreading
    root.render(<ContainerComponent {...container.dataset} />);
  });
}

(function contentLoaded() {
  initializeGTM();
  window.addEventListener('DOMContentLoaded', initializeReact, false);
})();
