import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header';

const ErrorContainer = ({
  requestId = null,
  errorType = 'internalServerError',
}) => (
  <div className="error-container">
    <Header>
      <div className="wink-h3 wink-nomargin">
        Squarespace Commerce Integration
      </div>
    </Header>
    <main className="error-detail">
      {
        {
          internalServerError: (
            <>
              <h1>Something Went Wrong</h1>
              <div>
                We&apos;re very sorry, but something went wrong on our side and
                we couldn&apos;t complete your request.
              </div>
            </>
          ),
          notFound: (
            <>
              <h1>The Page You Requested Doesn&apos;t Exist.</h1>
              <div>
                You may have mistyped the address or the page may have moved.
              </div>
            </>
          ),
          unprocessableEntity: (
            <>
              <h1>The Change You Requested Was Rejected</h1>
              <div>
                Maybe you tried to change something you don&apos;t have access
                to?
              </div>
            </>
          ),
        }[errorType]
      }
      {requestId && errorType !== 'notFound' && (
        <div>The request ID for this error is: {requestId}.</div>
      )}
    </main>
  </div>
);

ErrorContainer.propTypes = {
  requestId: PropTypes.string,
  errorType: PropTypes.string,
};

export default ErrorContainer;
